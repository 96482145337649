<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
        <Toolbar class="p-mb-4">
          <template #start>
            <div style="font-size: 1.4em; color: inherit">{{ $t('Employees archive') }}</div>
<!--            <AddNewButton :label="$t('Add')" @click="addNewItem" />-->
          </template>
          <template #end>
            <SearchInput :disabled="dataTableIsLoading" @change-processed-search-value="changeProcessedSearchValue" v-model="searchData" @show-spinner="isLoading = true"/>
          </template>
        </Toolbar>

        <Spinner v-if="isLoading"></Spinner>
<!--        <DesktopDataTable v-else-if="!isLoading && !$store.state.tabletLayout"-->
        <DesktopDataTable v-else-if="!isLoading"
                          :dataTableIsLoading="dataTableIsLoading"
                          :showHeader="false"
                          :tableName="$t('Employees archive')"
                          :itemsArrayIsEmpty="itemsArrayIsEmpty"
                          :sort="sort"
                          :showSearchInput="false"
                          :searchData="searchData"
                          :actionButtonsCount="actionButtonsCount"
                          @sort-items="sortItems"
                          @search-items="searchItems"
                          :headers="[
              {name: '', title: '',  width: '60px', sortable: false},
              {name: '', title: $t('Employee'),  width: '',sortable: false},
              // {name: 'language', class: 'ti-world', width: '80px', sortable: false},
              {name: 'role', title: $t('Role'), sortable: false},
              {name: 'position', title: $t('Position'), sortable: false},
              {name: 'birthdate', title: $t('Birthdate'), sortable: false},
              {name: 'user.phone_number', title: $t('Phone'), sortable: false},
              {name: 'work_phone_number', title: 'Work phone', sortable: false},
              {name: 'email', title: $t('Email'), sortable: false},
              {name: 'created_at', title: $t('Created'), sortable: true},
          ]">
          <template v-slot:body>
            <tr v-for="(item, index) of items"
                :key="index"
                :class="{'updated-item': isItemUpdated(item.id)}">
              <td class="avatar-td">
                <span class="avatar-wrapper">
                  <router-link :to="{ path: `/employees/${item.id}` }" target="_blank">
                    <img v-if="!item.avatar" :src="settings.apiEndPoint + settings.pathToAvatarsDir + 'avatar_placeholder.png'" :alt="item.first_name + ' ' + item.last_name" :title="item.first_name + ' ' + item.last_name">
                    <img v-else-if="item.avatar" :src="settings.apiEndPoint + settings.pathToAvatarsDir + item.avatar + '.jpg'" :alt="item.first_name + ' ' + item.last_name" :title="item.first_name + ' ' + item.last_name">
                  </router-link>
                </span>
              </td>
              <td>
                {{ item.first_name }} {{ item.last_name }}
              </td>
<!--              <td>-->
<!--                <span v-if="item.flag_code" :class="'flag&#45;&#45;datatable flag flag-' + item.flag_code"></span>-->
<!--              </td>-->
              <td>
                {{ revealEmployeeRole(item.employee?.role) }}
              </td>
              <td>
                {{ item.employee?.position }}
              </td>
              <td>
                <span v-if="item.birthdate">{{ formatBirthDate(item.birthdate) }}</span>
              </td>
              <td>
                <span v-if="item.phone_code && item.phone_number">
                  +{{ item.phone_code }} {{ item.phone_number }}
                </span>
              </td>
              <td>
                <span v-if="item.employee?.work_phone_code && item.employee?.work_phone_number">
                  +{{ item.employee.work_phone_code }} {{ item.employee.work_phone_number }}
                </span>
              </td>
              <td>
                {{ item.email }}
              </td>
              <td class="table-date-line">
                <span v-if="item.employee?.created_at">{{ formatDate(item.employee.created_at)}}, </span>
                <span v-if="item.employee?.creator" class="table-date-line">
                  <span v-if="item.employee.creator.type === constants.userTypes.legal_entity && item.employee.creator.company_name">{{ item.employee.creator.company_name }}<span> ({{ item.employee.creator.first_name }} {{ item.employee.creator.last_name }})</span></span>
                  <span v-else>{{ item.employee.creator.first_name }} {{ item.employee.creator.last_name }}</span>
                </span>
              </td>
              <td>
                <div class="p-d-flex">
<!--                  <router-link :to="{ path: `/employees/${item.user?.id}` }"><ViewButton/></router-link>-->
                  <router-link :to="{ path: `/employees/${item.id}` }"><ViewButton/></router-link>
                  <EditButton :disabled="!checkUserCanEditCustomer(item.role) || (disableEditButton && isItemUpdated(item.id)) || dataTableIsLoading" @click="editItem(item)" />
                  <DeleteButton :disabled="item.role === constants.userRoles.superAdmin || dataTableIsLoading" @click="confirmItemDelete(item)"
                                v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>
                </div>
              </td>
            </tr>
          </template>
        </DesktopDataTable>



<!--        <MobileDataTable v-else-if="!isLoading && $store.state.tabletLayout"-->
<!--                         :tableName="$t('Employees')"-->
<!--                         :showSearchInput="false"-->
<!--                         :itemsArrayIsEmpty="itemsArrayIsEmpty"-->
<!--                         :searchData="searchData"-->
<!--                         @search-items="searchItems">-->
<!--          <template v-slot:body>-->
<!--            <tr :class="{'updated-item': isItemUpdated(item.id)}"-->
<!--                v-for="(item, index) of items"-->
<!--                :key="index">-->
<!--              <td>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center p-pt-2">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Employee') }}:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center p-flex-wrap">-->
<!--                    <div>{{ item.first_name || '' }} {{ item.last_name || '' }}</div>-->
<!--                    <div><span :class="'flag&#45;&#45;datatable flag flag-' + item.flag_code" class="p-ml-1"></span></div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Role') }}:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center" v-if="item.role">{{ revealEmployeeRole(+item.role) }}</div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Position') }}:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <span v-if="item.employee && item.employee.position">{{ item.employee.position }}</span>-->
<!--                    <span v-else></span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Birthdate') }}:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center" v-if="item.birthdate">{{ formatBirthDate(item.birthdate) }}</div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Phone') }}:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center"><span v-if="item.phone_code">+</span>{{ item.phone_code || '' }} {{ item.phone_number || '' }}</div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">Work phone:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <span v-if="item.employee && item.employee.work_phone_code && item.employee.work_phone_number"><span v-if="item.employee.work_phone_code">+</span>{{ item.employee.work_phone_code || '' }} {{ item.employee.work_phone_number || '' }}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Email') }}:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center" v-if="item.email">{{ item.email }}</div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Created') }}:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <div class="table-date-line">-->
<!--                      <span v-if="item.created_at">{{ formatDate(item.created_at) || '' }}, </span>-->
<!--                      <span v-if="item.creator" class="table-date-line">-->
<!--                        <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}<span> ({{ item.creator.first_name }} {{ item.creator.last_name }})</span></span>-->
<!--                        <span v-else>{{ item.creator.first_name }} {{ item.creator.last_name }}</span>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-m-2 p-d-flex p-jc-center">-->
<!--                  <router-link :to="{ path: `/employees/${item.id}` }"><ViewButton/></router-link>-->
<!--                  <EditButton :disabled="!checkUserCanEditCustomer(item.role)" @click="editItem(item)" />-->
<!--                  <DeleteButton :disabled="item.role === constants.userRoles.superAdmin" @click="confirmItemDelete(item)"-->
<!--                                v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>-->
<!--                </div>-->
<!--              </td>-->
<!--            </tr>-->
<!--          </template>-->
<!--        </MobileDataTable>-->

        <OwnPaginator v-show="items && items.length"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>

        <EmployeeModal :visible="itemModal"
                     :item="item"
                     @update-items="updateItems"
                     @close="closeItemModal">
        </EmployeeModal>

        <ConfirmDeleteModal
            :visible="confirmItemDeleteModal"
            @close="closeConfirmItemDeleteModal"
            @delete-item="deleteItem"
            :data="itemToDeleteData">
        </ConfirmDeleteModal>
			</div>

		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import EmployeeModal from '@/pages/users/components/EmployeeModal'
import roles from '@/translations/roles'
import permissionsMixins from '@/mixins/permissionsMixins'
import formatMixins from '@/mixins/formatMixins'
import httpMixins from "@/mixins/httpMixins";
import datatableMixins from "@/mixins/datatableMixins";
import settings from "@/settings";

export default {
  mixins: [ permissionsMixins, formatMixins, httpMixins, datatableMixins ],
  components: { EmployeeModal },
	data() {
		return {
      // superAdminId: null,
      // fieldsExpand: 'employee, address, creator',
      fieldsExpand: 'employee,employee.creator,address',
      apiLink: 'user/get-archived-employees',
      routePath: '/employees-archive',
		}
	},
  // watch: {
  // },
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    this.checkRouteQuery()
    this.getItems()
	},
  // unmounted() {
  //   this.items = []
  // },
  methods: {
    async getItems() {
      this.dataTableIsLoading = true
      this.disableEditButton = true
      try {
        // const { status, data, headers } = await httpClient(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`)
        const { status, data, headers } = await httpClient({
          // const { data } = await httpClient({
          url: this.apiLink,
          // method: 'post',
          params: {
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand,
          },
          // data: {}
        })
        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']

          this.items = data

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }

          // const superAdmin = data.filter(item => item.role === constants.userRoles.superAdmin)[0]
          // if (superAdmin) {
          //   this.superAdminId = superAdmin.id
          // } else {
          //   this.superAdminId = null
          // }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
        this.disableEditButton = false
      }
    },
    async deleteItem() {
      console.log('delete employee')
      // try {
      //   const { status } = await httpClient.post('user/delete-user', {id: this.itemToDelete.id})
      //   console.log(status)
      //   if (status === 204) {
      //     this.$toast.add({severity:'success', detail: this.$t('Data deleted'), life: 1750});
      //     this.updatedItem = null
      //
      //     this.searchData = ''
      //     this.$router.push({
      //       route: this.$route.path,
      //       query: {
      //         ...this.$route.query,
      //         search: '',
      //       }
      //     })
      //
      //     this.getItems()
      //     this.closeConfirmItemDeleteModal()
      //   }
      // } catch(err) {
      //   this.showError(err)
      // }
    },
    revealEmployeeRole(roleNum) {
      if (!roleNum) return null
      return roles.find(role => role.num === +roleNum)?.name[this.$i18n.locale]
    },
	},
  computed: {
    actionButtonsCount() {
      if (this.$store.state.user.role === this.constants.userRoles.superAdmin || this.$store.state.user.role === this.constants.userRoles.admin) {
        return 3
      } else {
        return 2
      }
    },
    itemToDeleteData() {
        return (this.itemToDelete.first_name ? (this.itemToDelete.first_name) : '')  + (this.itemToDelete.last_name ? ' ' + this.itemToDelete.last_name : '')
    }
  }
}
</script>

<style scoped lang="scss">
</style>
